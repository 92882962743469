import React from "react";
import { useNavigate } from "react-router-dom";
import { IPatient } from "../../types/patient";

import {
    PatientCardContainer
} from './PatientCardStyled'

export interface Props extends Omit<IPatient, 'testHistory' | 'info' | 'age' | 'id' | 'gender'> {
    detailsLink: string;
    totalTests: number;
    personalScore: number;
    techScore: number;
    showName?: boolean;
    showNewtestButton?: boolean
    newTest: () => void;
}

const PatientCard: React.FC<Props> = ({
    patientName,
    lastUpdated,
    therapist,
    detailsLink,
    totalTests,
    personalScore,
    techScore,
    showName,
    showNewtestButton,
    newTest,
    ...props
}: Props) => {
    const navigate = useNavigate()
    return (
        <>
            <PatientCardContainer>
                <div className="cardTop">
                    {showName &&
                        <>
                            <big>{patientName}</big>
                            <em><span>Avaliações:</span> {totalTests}</em>
                            <em><span>Profissional:</span> {therapist}</em>
                        </>
                    }
                    <em><span>{showName ? "Última Avaliação" : "Data"}:</span> {lastUpdated}</em>
                </div>
                <div className="cardBottom">
                    <div className="score techScore">
                        <strong>
                            Média Técnica
                        </strong>
                        <big>{techScore}</big>
                    </div>
                    <div className="score personalScore">
                        <strong>Avaliação Pessoal</strong>
                        <big>{personalScore}</big>
                    </div>
                </div>
                <div className="cardButtons">
                    <button className="details" onClick={() => navigate(detailsLink)}>
                        Ver detalhes
                    </button>
                    {showNewtestButton &&
                        <button className="new" onClick={newTest}>
                            Nova Avaliação
                        </button>
                    }
                </div>
            </PatientCardContainer>
        </>
    );
};

export { PatientCard };
