import styled from "styled-components";

export const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 2rem 0 3rem 0;
`;
