export const headers = {
	headers: {
		"Content-Type": "application/json",
	},
};

export const multipartHeaders = {
	headers: {
		"Content-Type": "multipart/form-data",
	},
};

export const BASE_URL = "https://portal.seraqueeaba.com.br/api";
