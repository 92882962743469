import styled from "styled-components";
import { theme } from "../../globalStyles";
// import { Props } from "./index";

export const PatientCardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	background: white;
	font-style: italic;
	> div {
		width: 100%;
	}
	.cardTop,
	.cardBottom {
		padding: 0 16px;
	}
	.cardTop {
		padding-top: 16px;
		margin-bottom: 1.2rem;
		big,
		em {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 0.9rem;
		}
		em {
			font-weight: 700;
			span {
				font-weight: 400;
			}
			margin-bottom: 6px;
		}
		big {
			margin-bottom: 1rem;
			font-size: 1.6rem;
			font-weight: 700;
		}
	}
	.cardBottom {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding-bottom: 16px;
		strong,
		big {
			display: block;
			font-weight: 700;
			text-align: center;
		}
		strong {
			font-size: 0.9rem;
		}
		big {
			font-size: 1.7rem;
		}
	}
	.cardButtons {
		display: flex;
		button {
			flex: 1;
			padding: 16px 10px;
			text-align: center;
			font-style: italic;
			color: white;
			font-size: 1rem;
			transition: 0.3s;
			&:hover {
				opacity: 0.6;
			}
			&.details {
				background-color: ${theme.primaryFont};
			}
			&.new {
				background-color: ${theme.primaryColor};
				color: ${theme.primaryFont};
			}
		}
	}
`;
