import { lighten } from "polished";
import styled, { css } from "styled-components";
import { theme } from "../../globalStyles";
// import { Props } from "./index";
import media from "../../media";

export const NewCheckContainer = styled.div`
	width: 100%;

	.buttonWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		button {
			padding: 10px 24px;
			font-size: 1.145rem;
			color: white;
			background: ${theme.primaryFont};
			text-align: center;
			border-radius: 6px;
			transition: 0.3s;
			&:hover {
				opacity: 0.6;
			}
		}
	}
`;

export const Tab = styled.button<{ isActive: boolean }>`
	background-color: ${(props) => (props.isActive ? theme.primaryFont : lighten(0.2, theme.primaryFont))};
	color: white;
	padding: 14px 40px;
	/* border-radius: 10px 10px 0 0; */
	font-weight: 700;
	font-style: italic;
	font-size: 1.125rem;
	position: relative;
	${media.lessThan("medium")`
        font-size: .9rem;
        padding: 10px 20px;
    `}
	&:after {
		display: ${(props) => (props.isActive ? "block" : "none")};
		content: "";
		width: 12px;
		height: 12px;
		border-radius: 100px;
		position: absolute;
		background: white;
		border: 5px solid ${theme.primaryFont};
		left: 50%;
		transform: translateX(-50%);
	}
`;

export const TabsGroup = styled.div<{ location: "top" | "bottom" }>`
	display: flex;
	padding-left: 30px;
	width: 100%;
	justify-content: flex-start;
	gap: 6px;
	${(props) =>
		props.location === "bottom"
			? css`
					margin-bottom: 2rem;
			  `
			: ""}
	${Tab} {
		border-radius: ${(props) => (props.location === "top" ? "10px 10px 0 0" : "0 0 10px 10px")};
		&:after {
			${(props) =>
				props.location === "bottom"
					? css`
							top: -10px;
					  `
					: css`
							bottom: -10px;
					  `}
		}
	}
`;

export const TabContent = styled.div`
	background-color: white;
	border-radius: 20px;
	padding: 20px;
	.question {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		&:not(:last-child) {
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
		.title {
			width: 55%;
			font-style: italic;
			font-weight: 700;
			color: ${theme.primaryFont};
			letter-spacing: 0.5px;
			line-height: 1.2em;
			${media.lessThan("medium")`
			    width: auto;
            `}
		}
		.slider {
			flex: 1;
			padding-right: 1rem;
			.rc-slider-dot-active {
				border-color: ${theme.primaryFont};
			}
			.rc-slider-track,
			.rc-slider-handle {
				border-color: ${theme.primaryFont};
				background: ${theme.primaryColor};
			}
			.rc-slider-handle-dragging {
				box-shadow: 0 0 0 5px ${theme.primaryColor};
			}
			${media.lessThan("medium")`
                display: none;
            `}
		}
		.number {
			padding: 15px 16px 14px 14px;
			border-radius: 6px;
			font-size: 1.155rem;
			/* font-style: italic; */
			background-color: ${theme.primaryLight};
			text-align: center;
			border: 1px solid ${theme.primaryFont};
		}
		input[type="number"] {
			-moz-appearance: textfield;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}
`;
