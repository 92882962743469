import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";

export interface Props { }

const Logout: React.FC<Props> = ({ ...props }) => {

    const navigate = useNavigate()

    const handleLogout = useCallback(async () => {
        const userService = new UserService();
        await userService.logout();
        navigate('/')
    }, [navigate])

    useEffect(() => {
        handleLogout()
    }, [handleLogout])

    return (
        <div>Carregando...</div>
    );
};

export { Logout };
