import { IPatient } from "../types/patient";
import { formatPatientCheckResults } from "./formatPatientCheckResults";

export const formatSinglePatient = (patientAPI: any): IPatient => {
	const formatted: IPatient = {
		id: patientAPI.id,
		patientName: patientAPI.nome_paciente,
		age: patientAPI.idade_paciente,
		therapist: patientAPI.nome_profissional,
		info: patientAPI.informacoes_adicionais,
		lastUpdated: patientAPI.data_inicio,
		gender: patientAPI.genero_paciente,
		testHistory: patientAPI.avaliacoes ? formatPatientCheckResults(patientAPI.avaliacoes) : [],
		totalTests: patientAPI.avaliacoes_realizadas,
		personalScore: parseFloat(patientAPI.media_pontuacao_pessoal),
		techScore: parseFloat(patientAPI.media_pontuacao_tecnica),
	};
	return formatted;
};

export const formatPatientData = (patientAPI: any): IPatient[] => {
	const mappedPatients: IPatient[] = patientAPI.map((patientAPI: any) => {
		const formatted: IPatient = formatSinglePatient(patientAPI);
		return formatted;
	});
	return mappedPatients;
};
