import React, { ReactNode, useEffect, useState } from "react";
import {
    HeaderContainer,
    UserInfo
} from "./HeaderStyled";
import { FaBars } from 'react-icons/fa'
// import { Avatar } from "../Avatar";
import { Sidebar } from "../Sidebar";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import { IUser } from "../../types/lead";

export interface Props {
    children?: ReactNode;
}

const Header: React.FC<Props> = ({
    children,
    ...props
}: Props) => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
    const [user, setUser] = useState<IUser>({} as IUser)
    const navigate = useNavigate()

    const getUser = async () => {
        const userService = new UserService()
        const user = await userService.getUser()

        setUser(user)
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <>
            <HeaderContainer {...props}>
                <button onClick={() => setSidebarOpen(true)}>
                    <FaBars color="#FFF" size={24} />
                </button>
                <UserInfo>
                    <div className="infos">
                        <span>{user ? user.name : ""}</span>
                        <button onClick={() => navigate('/logout')}>Sair</button>
                    </div>
                    {/* <Avatar imgUrl="https://placeimg.com/300/300/people" userName="Usuário.nome" size={40} /> */}
                </UserInfo>
            </HeaderContainer>
            <Sidebar className={sidebarOpen ? "open" : "closed"} toggleState={() => setSidebarOpen(false)} />
        </>
    );
};

export { Header };
