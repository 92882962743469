import api from "./api";

export default class BaseService {
	public resource = "";
	constructor(resource: string) {
		this.resource = resource;
	}

	async list() {
		try {
			const response = await api({
				method: "GET",
				url: this.resource,
			});

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async getById(id: string | number) {
		try {
			const response = await api({
				method: "GET",
				url: `${this.resource}${id}`,
			});

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}
}
