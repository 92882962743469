import { IPatient } from "../types/patient";
import api from "./api";
import BaseService from "./base.service";
import { headers } from "./config";

export default class PatientsService extends BaseService {
	constructor() {
		super("/avaliacoes/testes/");
	}

	async create(patient: Omit<IPatient, "id" | "testHistory" | "totalTests" | "techScore" | "personalScore">) {
		const dataForAPI = {
			"nome_paciente": patient.patientName,
			"idade_paciente": patient.age,
			"genero_paciente": patient.gender,
			"data_inicio": patient.lastUpdated, //TODO: Colocar data dinamica aqui
			"nome_profissional": patient.therapist,
			"informacoes_adicionais": patient.info,
		};

		try {
			const response = await api.post(this.resource, dataForAPI, headers);
			return response.data;
		} catch (error) {
			console.error("Erro ao criar paciente", error);
			throw new Error("Houve um erro ao criar o paciente");
		}
	}
}
