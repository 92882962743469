import React, { ReactNode } from "react";
import { theme } from "../../globalStyles";
import { Header } from "../Header";
import { PageTitle } from "../PageTitle";
import { PageContainer } from "./PageContainerStyled";

export interface Props {
    children?: ReactNode;
    pageTitle: string;
}

const PageWrapper: React.FC<Props> = ({
    children,
    ...props
}: Props) => {
    return (
        <div style={{ 'background': theme.primaryLight, minHeight: 'calc(100vh - 110px)' }}>
            <Header />
            <PageContainer {...props}>
                <PageTitle text={props.pageTitle} />
                {children}
            </PageContainer>
        </div>
    );
};

export { PageWrapper as PageContainer };
