import React, { useEffect, useState } from "react";

import { IMaskInput } from 'react-imask';

import { PageContainer } from "../../components/PageContainer";
import UserService from "../../services/user.service";
import { IUser } from "../../types/lead";
import { ProfileContainer } from "./profileStyled";

export interface Props { }

interface IUserFields {
    "name": string,
    "telefone": string,
    relacao: "FAMILIAR" | "PROFISSIONAL" | null;
}

type TUserField = keyof IUserFields

const Profile: React.FC<Props> = ({ ...props }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [userFields, setUserFields] = useState<IUserFields>({} as IUserFields)

    const handleChangeFields = (event: any, field: TUserField) => {
        if (field === 'telefone') {
            setUserFields({ ...userFields, [field]: event })
        } else {
            setUserFields({ ...userFields, [field]: event.target.value })
        }
    }

    const getUser = async () => {
        const userService = new UserService()
        const user: IUser = await userService.getUser()
        setUserFields({
            name: user.name,
            telefone: user.telefone,
            relacao: user.relacao,
        })
    }

    const editUser = async () => {
        try {
            const userService = new UserService()
            await userService.updateUser(userFields)
            alert("Salvo com sucesso")
            window.location.reload();
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getUser()
    }, [])


    return (
        <PageContainer pageTitle="Seu Perfil">
            {loading ? "carregando" : <>
                <ProfileContainer>
                    <div className="form">
                        <label htmlFor="name">Nome</label>
                        <input type="text" name="name" onChange={(e) => handleChangeFields(e, "name")} value={userFields.name} />
                        <label htmlFor="phone">Telefone</label>
                        <IMaskInput placeholderChar="#" mask={"(00) 0000-0000[0]"} value={userFields.telefone} onAccept={(value) => handleChangeFields(value, "telefone")} />
                        <label htmlFor="relacao">Relação com o TEA</label>
                        <select name="relacao" onChange={(e) => handleChangeFields(e, "relacao")} value={userFields.relacao ? userFields.relacao : ''}>
                            <option value="FAMILIAR">Eu sou familiar de uma pessoa com TEA</option>
                            <option value="PROFISSIONAL">Eu sou terapeuta de uma pessoa com TEA</option>
                        </select>
                        <button onClick={editUser}>
                            Salvar
                        </button>
                    </div>
                </ProfileContainer>
            </>}
        </PageContainer>
    );
};

export { Profile };
