import styled from "styled-components";
import media from "../../media";
import { theme } from "../../globalStyles";

export const NewTestContainer = styled.div`
	display: grid;
	grid-template: 1fr 1fr 3fr 1fr / 3fr 1fr 2fr 2fr;
	gap: 1rem;

	${media.lessThan("medium")`
		grid-template: auto / 1fr;
		width: 100%;
	`}
`;

export const NewTestField = styled.div`
	label,
	input,
	textarea,
	select {
		width: 100%;
		color: ${theme.primaryFont};
	}
	input,
	textarea,
	select {
		padding: 1rem;
		border-radius: 8px;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
		font-size: 1.125rem;
	}
	textarea {
		min-height: 250px;
	}
	label {
		display: block;
		font-style: italic;
		margin-bottom: 4px;
	}

	&.full {
		grid-column: 1 / 5;
		${media.lessThan("medium")`
			grid-column: 1 / 1;
		`}
	}
	&.buttonWrapper {
		text-align: right;
		button {
			padding: 10px 24px;
			font-size: 1.145rem;
			color: white;
			background: ${theme.primaryFont};
			text-align: center;
			border-radius: 6px;
			transition: 0.3s;
			font-weight: 700;
			font-style: italic;
			text-transform: uppercase;
			&:hover {
				opacity: 0.6;
			}
		}
	}
`;
