import styled from "styled-components";
import { theme } from "../../globalStyles";
import media from "../../media";

const patientsListWidth = 53;
const columnGap = 2.5;

export const HomeContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${columnGap}rem;
	.buttonWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		button {
			padding: 10px 24px;
			font-size: 1.145rem;
			color: white;
			background: ${theme.primaryFont};
			text-align: center;
			border-radius: 6px;
			transition: 0.3s;
			&:hover {
				opacity: 0.6;
			}
		}
	}
	.patientsList {
		width: ${patientsListWidth}%;
		display: grid;
		grid-template: auto / 1fr 1fr;
		gap: 1rem;
		align-self: flex-start;
		${media.lessThan("medium")`
			grid-template: auto / 1fr;
			width: 100%;
		`}
	}
	.globalScore {
		width: calc(${100 - patientsListWidth}% - ${columnGap}rem);
		${media.lessThan("medium")`
			width: 100%;
		`}
		h2 {
			font-weight: 400;
			font-style: italic;
			color: ${theme.primaryFont};
			margin-bottom: 2rem;
		}
		.chart {
			width: 100%;
			padding: 2rem 2.2rem 1rem 0rem;
			background-color: white;
			border-radius: 10px;
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		}
	}
`;
