import styled from "styled-components";
import { theme } from "../../globalStyles";

export const SidebarContainer = styled.nav`
	width: ${theme.sidebarWidth};
	height: 100vh;
	padding: 0px 20px;
	background-color: white;
	position: fixed;
	top: 0;
	transition: 0.3s ease-out;
	left: -${theme.sidebarWidth};
	z-index: 10;
	&.open {
		left: 0;
	}
	.sidebarTop {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: ${theme.headerHeight};
		margin-bottom: 2rem;
		h1 {
			flex: 1;
			height: 50px;
			display: flex;
			align-items: center;
			img {
				max-width: 100px;
				max-height: 100%;
			}
		}
		button {
			padding: 0;
			background: none;
			transition: 0.3s;
			&:hover {
				opacity: 0.6;
			}
		}
	}
	ul {
		li {
			margin-bottom: 1.5rem;
			font-size: 0.95rem;
			width: 100%;
			a {
				color: ${theme.primaryFont};
				display: flex;
				align-items: center;
				width: 100%;
				gap: 0.8rem;
				span {
					display: block;
					margin-bottom: -5px;
				}
				em {
					display: flex;
					align-items: center;
					margin-left: auto;
				}
			}
		}
	}
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.3);
	transition: 0.3s;
	opacity: 0;
	display: none;
	&.open {
		display: block;
		opacity: 1;
	}
`;
