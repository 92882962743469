import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/PageContainer";
import PatientsService from "../../services/patients.service";
import { IPatient } from "../../types/patient";

import { NewTestContainer, NewTestField } from './NewTestStyled'

export interface Props { }

type TNewTestDataField = keyof Omit<IPatient, "id" | "testHistory" | "totalTests" | "techScore" | "personalScore">

const initialStateTestData = {
    patientName: '',
    age: 0,
    gender: "Masculino",
    lastUpdated: '',
    info: 'não informado',
    therapist: '',
}

const NewTest: React.FC<Props> = ({ ...props }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [newTestData, setNewTestData] = useState<Omit<IPatient, "id" | "testHistory" | "totalTests" | "techScore" | "personalScore">>(initialStateTestData)
    const handleChangeFields = (event: any, field: TNewTestDataField) => {
        setNewTestData({ ...newTestData, [field]: event.target.value })
    }
    const handleCreateNewtest = async () => {
        const allFieldsFilled = Object.values(newTestData).every(val => val ? true : false)
        if (!allFieldsFilled) return alert("Por favor preencha todos os campos.")

        setLoading(true)

        console.log({ ...newTestData })
        try {
            const patientsService = new PatientsService();
            const response = await patientsService.create(newTestData)

            console.log(response)
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
        }

        // setNewTestData(initialStateTestData)

        setLoading(false)
    }

    return (
        <PageContainer pageTitle="Novo Paciente">
            <NewTestContainer>
                <NewTestField>
                    <label htmlFor="nomePaciente">Nome do Paciente:</label>
                    <input type="text" name="nomePaciente" onChange={(e) => handleChangeFields(e, 'patientName')} />
                </NewTestField>
                <NewTestField>
                    <label htmlFor="idade">Idade:</label>
                    <input type="number" name="idade" onChange={(e) => handleChangeFields(e, 'age')} />
                </NewTestField>
                <NewTestField>
                    <label htmlFor="genero">Gênero:</label>
                    <select name="genero" onChange={(e) => handleChangeFields(e, 'gender')}>
                        <option value="Masculino">Masculino</option>
                        <option value="Feminino">Feminino</option>
                        <option value="Outro">Outro</option>
                    </select>
                    {/* <input type="text" name="genero" onChange={(e) => handleChangeFields(e, 'gender')} /> */}
                </NewTestField>
                <NewTestField>
                    <label htmlFor="dataInicio">Data de Início:</label>
                    <input type="date" name="dataInicio" onChange={(e) => handleChangeFields(e, 'lastUpdated')} />
                </NewTestField>
                <NewTestField className="full professional">
                    <label htmlFor="profissioonal">Nome do Profissional:</label>
                    <input type="text" name="profissional" onChange={(e) => handleChangeFields(e, 'therapist')} />
                </NewTestField>
                <NewTestField className="full aditionalInfo">
                    <label htmlFor="infosAdicionais">Informações Adicionais:</label>
                    <textarea name="infosAdicionais" onChange={(e) => handleChangeFields(e, 'info')}></textarea>
                </NewTestField>
                <NewTestField className="full buttonWrapper">
                    <button onClick={handleCreateNewtest} disabled={loading}>
                        Salvar
                    </button>
                </NewTestField>
            </NewTestContainer>
        </PageContainer>
    );
};

export { NewTest };
