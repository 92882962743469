import { ITestHistory } from "../types/patient";

export const formatPatientCheckResults = (rawData: any): ITestHistory[] => {
	const formatted: ITestHistory[] = rawData.map((rawData: any) => {
		let forma: ITestHistory = {
			id: rawData.id,
			data: rawData.created.split(" ")[0],
			tech: parseFloat(parseFloat(rawData.pontuacao_tecnica).toFixed(1)),
			pers: parseFloat(parseFloat(rawData.pontuacao_pessoal).toFixed(1)),
		};
		return forma;
	});
	return formatted.reverse();
};
