import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PageContainer } from "../../components/PageContainer";
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider'

import { NewCheckContainer, TabsGroup, Tab, TabContent } from "./newCheckStyled"
import { IAnswer, IQuestion } from "../../types/question";
import QuestionsService from "../../services/questions.service";

export interface Props { }

const NewCheck: React.FC<Props> = ({ ...props }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(1)
    const [questions, setQuestions] = useState<IQuestion[]>([])
    const [answers, setAnswers] = useState<IAnswer[]>([])
    const navigate = useNavigate()
    const { pid, avid } = useParams<{ pid: string, avid: string }>()
    const [searchParams] = useSearchParams()

    const getQuestions = async (avid: string | undefined) => {
        setLoading(true)

        const questionsService = new QuestionsService();

        const response = await questionsService.list();

        setQuestions(response.questoes)

        const respostas: IAnswer[] = []

        if (avid) {

            console.log("HAS ANS")
            const oldAnswers = await questionsService.getById(avid);

            const oldR = oldAnswers.respostas

            for (let i = 0; i < oldR.length; i++) {
                const m: IAnswer = {
                    id: oldR[i].id,
                    questao: oldR[i].questao,
                    resposta: oldR[i].resposta,
                    tipo: oldR[i].tipo_questao,
                }
                respostas.push(m)
            }
        } else {

            for (let i = 0; i < response.questoes.length; i++) {
                const m: IAnswer = {
                    questao: response.questoes[i].id,
                    resposta: 1,
                    tipo: response.questoes[i].tipo_questao,
                }
                respostas.push(m)
            }
        }

        console.log(respostas)

        setAnswers(respostas)

        setLoading(false)
    }

    const handleChangeAnswerGrade = (e: any, questao: IQuestion, ansId?: string | number) => {
        const filteredAnswers = answers.filter((ans: IAnswer) => ans.questao !== questao.id)
        let ans = e > 10 ? 10 : e;
        if (avid) {
            setAnswers([...filteredAnswers, { id: ansId, questao: questao.id, resposta: ans, tipo: questao.tipo_questao }])
        } else {
            setAnswers([...filteredAnswers, { questao: questao.id, resposta: ans, tipo: questao.tipo_questao }])
        }
    }

    const saveCheck = async () => {
        if (!pid) return
        console.log(answers)
        if (window.confirm("Confirma o envio das notas para esta avaliação?")) {
            try {
                const questionsService = new QuestionsService();
                if (avid) {
                    await questionsService.updateById(avid, answers);
                } else {
                    const allAnswered = answers.every((answer: IAnswer) => answer.resposta === 1)
                    const techAnswered = answers.filter((answer: IAnswer) => answer.tipo === 'TECNICO').every((answer: IAnswer) => answer.resposta === 1)
                    const persAnswered = answers.filter((answer: IAnswer) => answer.tipo === 'PESSOAL').every((answer: IAnswer) => answer.resposta === 1)

                    if (allAnswered) return alert("Você não deu nota a nenhuma das questões. Por favor atribua nota às questões antes de salvar.")
                    if (techAnswered) return alert("Você não deu nota a nenhuma das questões Técnicas. Por favor atribua nota às questões antes de salvar.")
                    if (persAnswered) return alert("Você não deu nota a nenhuma das questões Pessoais. Por favor atribua nota às questões antes de salvar.")

                    await questionsService.answer(pid, answers);
                }

                navigate(`/paciente/${pid}`)

            } catch (error) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        getQuestions(avid)
    }, [avid])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab])

    return (
        <PageContainer pageTitle={`Avaliando ${searchParams.get('pn') ? searchParams.get('pn') : ''}`}>
            <NewCheckContainer>
                <TabsGroup location="top">
                    <Tab isActive={activeTab === 1 ? true : false} onClick={() => setActiveTab(1)}>Av. Pessoal</Tab>
                    <Tab isActive={activeTab === 2 ? true : false} onClick={() => setActiveTab(2)}>Av. Técnica</Tab>
                </TabsGroup>
                {activeTab === 1 ?
                    <TabContent>
                        {loading ? <>Carregando</> : <>
                            {/* Content TAB Pessoal */}
                            {questions.filter(q => q.tipo_questao === 'PESSOAL').map(questao =>
                                <div className="question" key={questao.id}>
                                    <p className="title">{questao.enunciado}</p>
                                    <div className="slider">
                                        <Slider
                                            min={1}
                                            max={10}
                                            defaultValue={1}
                                            value={answers.find((ans: IAnswer) => ans.questao === questao.id)?.resposta}
                                            onChange={(e) => handleChangeAnswerGrade(e, questao, answers.find((ans: IAnswer) => ans.questao === questao.id)?.id)}
                                            step={1}
                                            dots
                                        />
                                    </div>
                                    <input className="number" type="number" min={1} max={10} value={answers.find((ans: IAnswer) => ans.questao === questao.id)?.resposta} onChange={(e) => handleChangeAnswerGrade(e.target.value, questao)} />
                                </div>
                            )}
                        </>}
                    </TabContent>
                    :
                    <TabContent>
                        {loading ? <>Carregando</> : <>
                            {/* Content TAB Técnica */}
                            {questions.filter(q => q.tipo_questao === 'TECNICO').map(questao =>
                                <div className="question" key={questao.id}>
                                    <p className="title">{questao.enunciado}</p>
                                    <div className="slider">
                                        <Slider
                                            min={1}
                                            max={10}
                                            defaultValue={1}
                                            value={answers.find((ans: IAnswer) => ans.questao === questao.id)?.resposta}
                                            onChange={(e) => handleChangeAnswerGrade(e, questao, answers.find((ans: IAnswer) => ans.questao === questao.id)?.id)}
                                            step={1}
                                            dots
                                        />
                                    </div>
                                    <input className="number" type="number" min={1} max={10} value={answers.find((ans: IAnswer) => ans.questao === questao.id)?.resposta} onChange={(e) => handleChangeAnswerGrade(e.target.value, questao)} />
                                </div>
                            )}
                        </>}
                    </TabContent>
                }
                <TabsGroup location="bottom">
                    <Tab isActive={activeTab === 1 ? true : false} onClick={() => setActiveTab(1)}>Av. Pessoal</Tab>
                    <Tab isActive={activeTab === 2 ? true : false} onClick={() => setActiveTab(2)}>Av. Técnica</Tab>
                </TabsGroup>
                <div className="buttonWrapper">
                    {activeTab === 1 ? <>
                        <button className="newTest" onClick={() => setActiveTab(2)}>
                            <b>Próximo</b>
                        </button></> : <>
                        <button className="newTest" onClick={saveCheck}>
                            <b>Salvar</b>
                        </button>
                    </>}
                </div>
            </NewCheckContainer>
        </PageContainer>
    );
};

export { NewCheck };
