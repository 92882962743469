import React, { useState } from "react";
import { IMaskInput } from 'react-imask';


import {
    LoginContainer,
    CreateAccountForm,
    FormWrapper
} from "./loginStyled"

import Logo from "../../assets/images/logo-br.png"
import { ICreateUserFields, ILoginFields } from "../../types/lead";
import { ShowPasswordBtn } from "../../components/ShowPasswordBtn";
import UserService from "../../services/user.service";
import { useNavigate } from "react-router-dom";

export interface Props { }

type TField = keyof ICreateUserFields

type TLoginField = keyof ILoginFields

const Login: React.FC<Props> = ({ ...props }) => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)

    const [login, setLogin] = useState<boolean>(true)
    const [loginFields, setLoginFields] = useState<Omit<ILoginFields, 'username'>>({
        email: "",
        password: "",
    })
    const [showPassword, setShowPassword] = useState({
        loginPassword: false,
        createPassword: false,
        confirmPassword: false,
    })
    const [createAccountFields, setCreateAccountFields] = useState<ICreateUserFields>({
        name: "",
        telefone: "",
        email: "",
        relacao: 'FAMILIAR',
        password: '',
        password2: '',
    })

    const handleShowPassword = (field: keyof typeof showPassword) => {
        setShowPassword({ ...showPassword, [field]: !showPassword[field] })
    }

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleChangeFields = (event: any, field: TField) => {
        if (field === 'telefone') {
            setCreateAccountFields({ ...createAccountFields, [field]: event })
        } else {
            setCreateAccountFields({ ...createAccountFields, [field]: event.target.value })
        }
    }
    const handleChangeLoginFields = (event: any, field: TLoginField) => {
        setLoginFields({ ...loginFields, [field]: event.target.value })
    }

    const handleCreateAccount = async () => {
        const allFieldsFilled = Object.values(createAccountFields).every(val => val ? true : false)
        //validação de campos
        if (!allFieldsFilled) return alert("Por favor preencha todos os campos.")
        if (!validateEmail(createAccountFields.email)) alert("Por favor utilize um email válido")
        if (createAccountFields.password !== createAccountFields.password2) return alert('A senha deve ser igual à confirmação de senha.')

        const localEmail = createAccountFields.email.trim().toLowerCase()

        setLoading(true)

        setLoginFields({
            email: localEmail,
            password: createAccountFields.password,
        })


        try {
            const userService = new UserService();

            await userService.create({ ...createAccountFields, email: createAccountFields.email.toLowerCase().trim() })

            await handleLogin()
        } catch (error) {
            alert('Houve um erro ao criar o usuário, tente novamente mais tarde.')
        }
        setLoading(false)
    }

    const handleLogin = async () => {
        const allFieldsFilled = Object.values(loginFields).every(val => val ? true : false)
        if (!allFieldsFilled) return alert("Por favor preencha todos os campos.")
        setLoading(true)
        try {

            const localEmail = loginFields.email.trim().toLowerCase()

            const userService = new UserService();
            await userService.login({ ...loginFields, username: localEmail, email: localEmail })

            navigate('/dashboard')

        } catch (error) {
            alert('Houve um erro ao fazer login, tente novamente mais tarde.')
            console.error(error)
        }
        setLoading(false)
    }

    return (
        <LoginContainer>
            <FormWrapper>
                <img src={Logo} alt="Será que é ABA?" />
                {login ? <>
                    <CreateAccountForm>
                        <label htmlFor="loginemail">Email</label>
                        <input type="text" name="loginemail" placeholder="Email" onChange={(e) => handleChangeLoginFields(e, "email")} />
                        <label htmlFor="loginpass">Senha</label>
                        <div className="passwordField">
                            <input name="loginpass" type={showPassword.loginPassword ? "text" : "password"} placeholder="Senha" onChange={(e) => handleChangeLoginFields(e, "password")} />
                            <ShowPasswordBtn show={showPassword.createPassword} onClick={() => handleShowPassword("loginPassword")} />
                        </div>
                        <button onClick={handleLogin}>Entrar</button>
                        <button className="linkBtn" onClick={() => setLogin(false)}>Criar minha conta</button>
                    </CreateAccountForm>
                </> : (<>
                    <CreateAccountForm>
                        <label htmlFor="name">Nome</label>
                        <input type="text" name="name" onChange={(e) => handleChangeFields(e, "name")} />
                        <label htmlFor="phone">Telefone</label>
                        <IMaskInput placeholderChar="#" mask={"(00) 0000-0000[0]"} onAccept={(value) => handleChangeFields(value, "telefone")} />
                        {/* <input type="text" placeholder="Telefone" onChange={(e) => handleChangeFields(e, "telefone")} /> */}
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" onChange={(e) => handleChangeFields(e, "email")} />
                        <label htmlFor="relacao">Relação com o TEA</label>
                        <select name="relacao" onChange={(e) => handleChangeFields(e, "relacao")}>
                            <option value="FAMILIAR">Eu sou familiar de uma pessoa com TEA</option>
                            <option value="PROFISSIONAL">Eu sou terapeuta de uma pessoa com TEA</option>
                        </select>
                        <label htmlFor="pass">Senha</label>
                        <div className="passwordField">
                            <input name="pass" type={showPassword.createPassword ? "text" : "password"} onChange={(e) => handleChangeFields(e, "password")} />
                            <ShowPasswordBtn show={showPassword.createPassword} onClick={() => handleShowPassword("createPassword")} />
                        </div>
                        <label htmlFor="pass2">Confirme a Senha</label>
                        <div className="passwordField">
                            <input name="pass2" type={showPassword.confirmPassword ? "text" : "password"} onChange={(e) => handleChangeFields(e, "password2")} />
                            <ShowPasswordBtn show={showPassword.confirmPassword} onClick={() => handleShowPassword("confirmPassword")} />
                        </div>
                        <button onClick={handleCreateAccount}>
                            Criar minha conta
                        </button>
                        <button className="linkBtn" onClick={() => setLogin(true)}>Já tenho uma conta</button>
                    </CreateAccountForm>
                </>)}
            </FormWrapper>
        </LoginContainer>
    );
};

export { Login };
