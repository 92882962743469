import React, { ReactNode } from "react";
import { FaAngleRight, FaTimes } from "react-icons/fa";
import { GrHomeRounded, GrScorecard, GrUser, GrUserManager } from "react-icons/gr";
import { Link } from "react-router-dom";
import { theme } from "../../globalStyles";
import {
    SidebarContainer,
    Overlay
} from "./SidebarStyled";

import Logo from '../../assets/images/logo-br.png'

export interface Props {
    children?: ReactNode;
    className: string;
    toggleState: () => void;
}

const Sidebar: React.FC<Props> = ({
    children,
    className,
    toggleState,
    ...props
}: Props) => {
    return (
        <>
            <Overlay onClick={toggleState} className={className} />
            <SidebarContainer {...props} className={className}>
                <div className="sidebarTop">
                    <h1>
                        <img src={Logo} alt="Será que é ABA?" />
                    </h1>
                    <button onClick={toggleState}>
                        <FaTimes size={24} color={theme.primaryFont} />
                    </button>
                </div>
                <ul>
                    <li>
                        <Link to='/dashboard'>
                            <GrHomeRounded size={20} color={theme.primaryFont} />
                            <span>Home</span>
                            <em><FaAngleRight size={16} color={theme.primaryFont} /></em>
                        </Link>
                    </li>
                    <li>
                        <Link to='/perfil'>
                            <GrUserManager size={20} color={theme.primaryFont} />
                            <span>Seu Perfil</span>
                            <em><FaAngleRight size={16} color={theme.primaryFont} /></em>
                        </Link>
                    </li>
                    <li>
                        <Link to='/novo-paciente'>
                            <GrScorecard size={20} color={theme.primaryFont} />
                            <span>Novo Paciente</span>
                            <em><FaAngleRight size={16} color={theme.primaryFont} /></em>
                        </Link>
                    </li>
                </ul>
            </SidebarContainer>
        </>
    );
};

export { Sidebar };
