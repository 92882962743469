import styled from "styled-components";
import { theme } from "../../globalStyles";
import { Props } from "./index";
import media from "../../media";

export const PatientDetailsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	width: 100%;
	.buttonWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		button {
			padding: 10px 24px;
			font-size: 1.145rem;
			color: white;
			background: ${theme.primaryFont};
			text-align: center;
			border-radius: 6px;
			transition: 0.3s;
			&:hover {
				opacity: 0.6;
			}
		}
	}
	h3 {
		margin-bottom: 1rem;
	}
	.patientInfo {
		width: 48%;

		${media.lessThan("medium")`
			width: 100%;
		`}
		.basicInfo {
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;
			margin-bottom: 2rem;
		}
		.patientHistory {
			margin-top: 2rem;
			display: grid;
			grid-template: auto / 1fr 1fr;
			gap: 1rem;
			${media.lessThan("medium")`
                grid-template: auto / 1fr
            `}
		}
	}
	.patientCharts {
		width: 48%;
		${media.lessThan("medium")`
			width: 100%;
		`}
		.chart {
			background-color: white;
			margin-bottom: 2rem;
			width: 100%;
			padding: 2rem 2.2rem 1rem 0rem;
			background-color: white;
			border-radius: 10px;
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		}
		.sideBySideCharts {
			display: flex;
			flex-wrap: wrap;
			.left,
			.right {
				width: 48%;
			}
		}
	}
`;
