import { localStorageKeyPrefix } from "../helpers/localstorage";
import { ICreateUserFields, ILoginFields } from "../types/lead";
import api from "./api";
import { getRefreshToken } from "./auth";
import { headers } from "./config";

export default class UserService {
	private resource = "";
	constructor() {
		this.resource = "";
	}

	async create(fields: ICreateUserFields) {
		localStorage.removeItem(`${localStorageKeyPrefix}-token`);
		localStorage.removeItem(`${localStorageKeyPrefix}-refresh`);
		try {
			const response = await api.post("/usuarios/criar-usuario/", fields, headers);
			return response.data;
		} catch (error) {
			console.error("Erro ao criar usuário", error);
			return { error: "Erro ao criar usuário, tente novamente mais tarde." };
		}
	}

	async login(fields: ILoginFields) {
		localStorage.removeItem(`${localStorageKeyPrefix}-token`);
		localStorage.removeItem(`${localStorageKeyPrefix}-refresh`);
		try {
			const response = await api.post("/rest-auth/login/", fields, headers);
			console.log(response.data);
			localStorage.setItem(`${localStorageKeyPrefix}-token`, response.data.access_token);
			localStorage.setItem(`${localStorageKeyPrefix}-refresh`, response.data.refresh_token);

			return { success: response.data };
		} catch (err) {
			console.error("erro durante login:", err);
			return { error: "usuário ou senha incorretos." };
		}
	}

	async logout() {
		localStorage.removeItem(`${localStorageKeyPrefix}-token`);
		localStorage.removeItem(`${localStorageKeyPrefix}-refresh`);
		return true;
	}

	async refreshToken() {
		try {
			const response = await api.post(`/rest-auth/token/refresh/`, { refresh: getRefreshToken() }, headers);
			localStorage.setItem(`${localStorageKeyPrefix}-token`, response.data.access);

			return response.data;
		} catch (err) {
			console.error("erro durante refresh:", err);

			localStorage.removeItem(`${localStorageKeyPrefix}-token`);
			localStorage.removeItem(`${localStorageKeyPrefix}-refresh`);

			// if (window.location.pathname !== "/") {
			// 	localStorage.setItem("redirectUrl", window.location.pathname);
			// }
			// const redirectLogin = `${window.location.origin}/`;
			// window.location.href = redirectLogin;
		}
	}

	async getUser() {
		try {
			const response = await api({
				method: "GET",
				url: "/usuarios/get-usuario/",
			});

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao recuperar usuário base service:`, err);
			return {
				error: true,
			};
		}
	}

	async updateUser(data: any) {
		try {
			const response = await api.put("/usuarios/editar-usuario/", data, headers);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao editar usuário base service:`, err);
			return {
				error: true,
			};
		}
	}
}
