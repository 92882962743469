import axios from "axios";
import { getToken } from "./auth";
import { BASE_URL } from "./config";
import jsonToFormData from "json-form-data";
import UserService from "./user.service";
import { localStorageKeyPrefix } from "../helpers/localstorage";

const api = axios.create({
	baseURL: `${BASE_URL}`,
	timeout: 10000,
});

api.interceptors.request.use(async (config) => {
	const token = getToken();

	if (token && config.headers) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	config.withCredentials = true;

	if (config.data && config.headers && config.headers["Content-Type"] === "multipart/form-data") {
		const options = {
			initialFormData: new FormData(),
			showLeafArrayIndexes: true,
			includeNullValues: false,
		};
		config.data = jsonToFormData(config.data, options);
	}

	return config;
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		console.group("Response Error Interceptor");
		console.log("ERROR----", error);
		console.log("ERROR----config", error.config);
		console.log("ERROR----URL", error.config.url);
		console.groupEnd();
		const originalReq = error.config;
		if (error.config && !error.config._retry) {
			originalReq._retry = true;
			// Return any error which is not due to authentication back to the calling service
			if ((error.response.status !== 401 && error.response.status !== 403) || error.config.url === "token/") {
				return new Promise((resolve, reject) => {
					reject(error);
				});
			}
			if (
				// error.config.url.includes("refresh") ||
				error.response.message === "Account is disabled."
				// || error.response.data.code === "token_not_valid"
			) {
				localStorage.removeItem(`${localStorageKeyPrefix}-token`);
				localStorage.removeItem(`${localStorageKeyPrefix}-refresh`);
				// const redirectLogin = `${window.location.origin}/`;
				// window.location.href = redirectLogin;
			}

			// Try request again with new token
			const loginService = new UserService();

			return loginService
				.refreshToken()
				.then(() => {
					// New request with new token
					const { config } = error;
					return new Promise((resolve, reject) => {
						api
							.request(config)
							.then((response) => {
								resolve(response);
							})
							.catch((err) => {
								reject(err);
								// const redirectLogin = `${window.location.origin}/`;
								// window.location.href = redirectLogin;
							});
					});
				})
				.catch((errors) => {
					Promise.reject(errors);
				});
		}
	}
);

export default api;
