import React from "react";

export interface Props { }

const NotFound: React.FC<Props> = ({ ...props }) => {


    return (
        <div>Teste</div>
    );
};

export { NotFound };
