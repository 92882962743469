import { createGlobalStyle, css } from "styled-components";

import NeoSansRegular from "../src/assets/fonts/NeoSansPro-Regular.ttf";
import NeoSansRegularItalic from "../src/assets/fonts/NeoSansPro-Italic.ttf";
import NeoSansBold from "../src/assets/fonts/NeoSansPro-Bold.ttf";
import NeoSansBoldItalic from "../src/assets/fonts/NeoSansPro-BoldItalic.ttf";

export const fontFaces = css`
	@font-face {
		font-family: "NeoSans";
		src: url(${NeoSansRegular});
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: "NeoSans";
		src: url(${NeoSansRegularItalic});
		font-weight: 400;
		font-style: italic;
	}
	@font-face {
		font-family: "NeoSans";
		src: url(${NeoSansBold});
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: "NeoSans";
		src: url(${NeoSansBoldItalic});
		font-weight: 700;
		font-style: italic;
	}
`;

export const GlobalStyles = createGlobalStyle`
    ${fontFaces}
    html {
        scroll-behavior: smooth;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        border: 0;
        &:focus, &::visited{
            outline: none
        }
        font-family: 'NeoSans', Arial, Helvetica, sans-serif;
    }
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'NeoSans', Arial, Helvetica, sans-serif;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    i {
        font-style: normal;
    }
    ul, li, ol {
        list-style: none;
        line-height: 1.2em;
    }
    body {
        font-size: 16px;
    }
    img {
        /* image-rendering: -webkit-optimize-contrast; // sharpen scaled down images */
        max-width: 100%;
    }
    button {
        cursor: pointer;
        &:disabled {
            cursor: default;
            filter: saturate(0) brightness(.7) !important;
            opacity: .8 !important;
        }
    }
    button, input, textarea, select {
        outline: none
    }
    a {
        text-decoration: none;
    }
    p, textarea {
        line-height: 1.2em;
    }
    .loading {
        width: 100%;
        display: flex;
        min-height: 200px;
        justify-content: center;
        align-items: center;
    }
`;

export const theme = {
	primaryColor: "#99CCCC",
	primaryLight: "#E6F4F4",
	primaryFont: "#003333",
	buttonColor: "#71B9B9",
	secondaryButton: "#ABD5D5",
	headerHeight: "60px",
	sidebarWidth: "250px",
};
