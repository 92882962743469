import React, { useEffect, useState } from "react";

import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts'

import { HomeContainer } from './homeStyled'
import { PageContainer } from "../../components/PageContainer";
import { PatientCard } from "../../components/PatientCard";
import { theme } from "../../globalStyles";
import { useNavigate } from "react-router-dom";
import PatientsService from "../../services/patients.service";
import { IPatient } from "../../types/patient";
import { formatPatientData } from "../../helpers/formatPatientData";

export interface Props { }

const data = [
    {
        "data": "01/10/2022",
        "tech": 4.5,
        "pers": 5,
    },
    {
        "data": "08/10/2022",
        "tech": 3,
        "pers": 5.5,
    },
    {
        "data": "15/10/2022",
        "tech": 5.5,
        "pers": 4.5,
    },
    {
        "data": "22/10/2022",
        "tech": 6.5,
        "pers": 6,
    },
    {
        "data": "29/10/2022",
        "tech": 8,
        "pers": 8,
    },
]

const Home: React.FC<Props> = ({ ...props }) => {
    const navigate = useNavigate()
    const [patients, setPatients] = useState<IPatient[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const getPatients = async () => {
        setLoading(true)
        try {
            const patientsService = new PatientsService();
            const response = await patientsService.list();
            setPatients(formatPatientData(response))
        } catch (error) {

        }
        setLoading(false)
    }

    useEffect(() => {
        getPatients()
    }, [])

    return (
        <PageContainer pageTitle="Seus Pacientes">
            <HomeContainer>
                <div className="buttonWrapper">
                    <button className="newTest" onClick={() => navigate('/novo-paciente')}>
                        Novo <b>Paciente</b>
                    </button>
                </div>
                <div className="patientsList">
                    {loading && "Carregando..."}
                    {patients.length > 0 && patients.map((patient: IPatient) =>
                        <PatientCard
                            patientName={patient.patientName}
                            lastUpdated={patient.lastUpdated}
                            therapist={patient.therapist}
                            detailsLink={`/paciente/${patient.id}`}
                            totalTests={patient.totalTests}
                            personalScore={patient.personalScore ? patient.personalScore : 0}
                            techScore={patient.techScore ? patient.techScore : 0}
                            newTest={() => navigate(`/paciente/${patient.id}/avaliacao/?pn=${patient.patientName}`)}
                            showName
                            showNewtestButton
                            key={patient.id}
                        />
                    )}
                </div>
                {/* <div className="globalScore">
                    <h2>Média Global</h2>
                    {loading && "Carregando..."}
                    <div className="chart">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={data} >
                                <CartesianGrid strokeDasharray="3" />
                                <XAxis dataKey="data" fontSize={10} interval={0} />
                                <YAxis domain={[0, 10]} />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="tech" name="Técnica" strokeWidth={2} stroke={theme.primaryColor} dot />
                                <Line type="monotone" dataKey="pers" name="Pessoal" strokeWidth={2} stroke={theme.primaryFont} dot />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div> */}
            </HomeContainer>
        </PageContainer>
    );
};

export { Home };
