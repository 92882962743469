import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { Login } from './pages/login'
import { Home } from './pages/home'
import { NotFound } from './pages/not-found'
import { NewTest } from './pages/new-test'
import { NewCheck } from './pages/new-check'

import { AppContainer } from './appStyled'

import logoAcademia from './assets/images/logo_academia_latam.png'
import logoB42 from './assets/images/logo-b42.png'
import { PatientDetails } from './pages/patient-details'
import { localStorageKeyPrefix } from './helpers/localstorage'
import { Logout } from './pages/logout'
import { Profile } from './pages/profile'

const isAuth = () => {
    if (localStorage.getItem(`${localStorageKeyPrefix}-token`)) {
        return true
    }
    return false
}

function TheRoute() {
    let element = useRoutes([
        {
            path: '/',
            element: <Login />,
        },
        {
            path: '/dashboard',
            element: isAuth() ? <Home /> : <Navigate to="/" />,
        },
        {
            path: '/perfil',
            element: isAuth() ? <Profile /> : <Navigate to="/" />,
        },
        {
            path: '/novo-paciente',
            element: isAuth() ? <NewTest /> : <Navigate to="/" />,
        },
        {
            path: '/paciente/:id',
            element: isAuth() ? <PatientDetails /> : <Navigate to="/" />,
        },
        {
            path: '/paciente/:pid/avaliacao/',
            element: isAuth() ? <NewCheck /> : <Navigate to="/" />,
        },
        {
            path: '/paciente/:pid/avaliacao/:avid',
            element: isAuth() ? <NewCheck /> : <Navigate to="/" />,
        },
        {
            path: '/logout',
            element: <Logout />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ])
    return element
}




export default function Router() {
    return (
        <AppContainer>
            <TheRoute />
            <footer>
                <h6><img src={logoAcademia} alt="Academia do Autismo" /></h6>
                <h6><img src={logoB42} alt="Agência B42" /></h6>
            </footer>
        </AppContainer>
    )
}