import styled from "styled-components";
import { theme } from "../../globalStyles";
import media from "../../media";

export const PageTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	margin: 0 0 2rem;
	position: relative;
	.logo,
	h2 {
		background: ${theme.primaryLight};
		position: relative;
		z-index: 1;
	}
	.logo {
		max-width: 200px;
		display: block;
		padding-left: 15px;
		${media.lessThan("medium")`
			max-width: 100px;
		`}
	}
	h2 {
		padding: 0 15px 5px 0;
		font-size: 2.5rem;
		font-weight: 500;
		font-style: italic;
		${media.lessThan("medium")`
			font-size: 1.25rem;
		`}
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 0;
		bottom: 13px;
		left: 0;
		width: 100%;
		height: 1px;
		border-bottom: 1px solid ${theme.primaryColor};
	}
`;
