import React from "react";

import {
    ShowPasswordBtnContainer
} from './ShowPasswordBtnStyled'

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { theme } from "../../globalStyles";

export interface Props {
    show: boolean
    onClick: () => void
}

const ShowPasswordBtn: React.FC<Props> = ({
    show,
    onClick,
    ...props
}: Props) => {
    return (
        <ShowPasswordBtnContainer className="togglePass" onClick={onClick}>
            {show ? <FaEye size={16} color={theme.primaryFont} /> : <FaEyeSlash size={16} color={theme.primaryFont} />}
        </ShowPasswordBtnContainer>
    );
};

export { ShowPasswordBtn };
