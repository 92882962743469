import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts'

import { PageContainer } from "../../components/PageContainer";
import { PatientCard } from "../../components/PatientCard";
import { theme } from "../../globalStyles";
import { formatSinglePatient } from "../../helpers/formatPatientData";
import PatientsService from "../../services/patients.service";
import { IPatient, ITestHistory } from "../../types/patient";
import { PatientDetailsContainer } from "./patientDetailsStyled";

type TScore = { data: string, score: number }
interface IScores {
    tech: TScore[],
    pers: TScore[]
}

export interface Props { }

const PatientDetails: React.FC<Props> = ({ ...props }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [patient, setPatient] = useState<IPatient>({} as IPatient)
    // const [scores, setScores] = useState<IScores>({} as IScores)
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    const getPatient = async (id: string | undefined) => {
        if (!id) return
        setLoading(true)

        const patientService = new PatientsService()
        const response = await patientService.getById(id)

        const patient = formatSinglePatient(response)

        setPatient(patient)

        setLoading(false)
    }

    /*const getScores = (patient: IPatient) => {
        let personal: TScore[] = patient.testHistory.map((test: ITestHistory): TScore => {
            return {
                data: test.data,
                score: test['pers']
            }
        })
        let technical: TScore[] = patient.testHistory.map((test: ITestHistory): TScore => {
            return {
                data: test.data,
                score: test['tech']
            }
        })
        setScores({ pers: personal.reverse(), tech: technical.reverse() })
    } */

    useEffect(() => {
        getPatient(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <PageContainer pageTitle={patient.patientName}>
            {loading ? "carregando" : <>
                <PatientDetailsContainer>
                    <div className="buttonWrapper">
                        <button className="newTest" onClick={() => navigate(`/paciente/${id}/avaliacao/?pn=${patient.patientName}`)}>
                            Nova <b>Avaliação</b>
                        </button>
                    </div>
                    <div className="patientInfo">
                        <div className="basicInfo">
                            <div className="info">Gênero: <b>{patient.gender}</b></div>
                            <div className="info">Idade: <b>{patient.age}</b></div>
                            <div className="info">Profissional: <b>{patient.therapist}</b></div>
                        </div>
                        <h3>Informações Adicionais</h3>
                        <p dangerouslySetInnerHTML={{ __html: patient.info }}>
                        </p>
                        {patient && patient.testHistory && patient.testHistory.length > 0 &&
                            <div className="patientHistory">
                                {patient.testHistory.map((test: ITestHistory) =>
                                    <PatientCard
                                        patientName="does not show"
                                        lastUpdated={test.data}
                                        therapist="does not show"
                                        detailsLink={`/paciente/${id}/avaliacao/${test.id}`}
                                        totalTests={0}
                                        personalScore={test.pers}
                                        techScore={test.tech}
                                        newTest={() => { }}
                                        key={test.id}
                                    />
                                )}
                            </div>
                        }
                    </div>
                    <div className="patientCharts">
                        {patient && patient.testHistory && patient.testHistory.length > 0 && <>
                            <h3>Histórico</h3>
                            <div className="chart">
                                <ResponsiveContainer width="100%" height={400}>
                                    <LineChart data={patient.testHistory} >
                                        <CartesianGrid strokeDasharray="3" />
                                        <XAxis dataKey="data" fontSize={10} interval={0} />
                                        <YAxis domain={[0, 10]} />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="tech" name="Técnica" strokeWidth={2} stroke={theme.primaryColor} dot />
                                        <Line type="monotone" dataKey="pers" name="Pessoal" strokeWidth={2} stroke={theme.primaryFont} dot />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </>}
                    </div>
                </PatientDetailsContainer>
            </>}
        </PageContainer>
    );
};

export { PatientDetails };
