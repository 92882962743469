import styled from "styled-components";
import { theme } from "../../globalStyles";

export const HeaderContainer = styled.header`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 5px 20px;
	height: ${theme.headerHeight};
	background-color: ${theme.primaryColor};
	color: white;
	button {
		background: none;
	}
`;

export const UserInfo = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 0.8rem;
	.infos {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
	span {
		width: 100%;
		display: inline-block;
		text-align: right;
	}
	button {
		color: white;
	}
`;
