import styled from "styled-components";
import { theme } from "./globalStyles";

export const AppContainer = styled.div`
	min-height: 100vh;
	footer {
		background: ${theme.primaryColor};
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem;
		gap: 3rem;
	}
`;
