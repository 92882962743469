import React from "react";
import { PageTitleContainer } from "./PageTitleStyled";

import Logo from '../../assets/images/logo-br.png'


export interface Props {
    text: string;
}

const PageTitle: React.FC<Props> = ({
    ...props
}: Props) => {
    return (
        <PageTitleContainer {...props}>
            <h2>{props.text}</h2>
            <span className="logo">
                <img src={Logo} alt="Será que é ABA?" />
            </span>
        </PageTitleContainer>
    );
};

export { PageTitle };
