import styled from "styled-components";
import { theme } from "../../globalStyles";
// import media from "../../styles/media";

export const LoginContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FormWrapper = styled.div`
	padding: 20px;
	border-top: 5px solid ${theme.primaryColor};
	background-color: ${theme.primaryLight};
	width: 90%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		max-width: 80%;
		margin-bottom: 2.5rem;
	}
`;

export const CreateAccountForm = styled.div`
	label {
		font-size: 0.9rem;
		color: ${theme.primaryFont};
	}
	input,
	select {
		width: 100%;
		background: white;
		border: 1px solid ${theme.primaryColor};
		padding: 10px;
		font-size: 1.125rem;
		color: ${theme.primaryFont};
		margin-bottom: 1rem;
	}
	.passwordField {
		position: relative;
	}
	button {
		&:not(.togglePass) {
			width: 100%;
			color: ${theme.primaryFont};
			&:not(.linkBtn) {
				padding: 12px;
				text-align: center;
				background-color: ${theme.buttonColor};
				font-weight: 700;
				font-size: 1.125rem;
				margin-bottom: 8px;
				&:hover {
					opacity: 0.7;
				}
			}
			background: none;
		}
		&.togglePass {
			position: absolute;
			top: 44%;
			right: 10px;
			transform: translateY(-50%);
			background: none;
		}
	}
`;
