import styled from "styled-components";
import { theme } from "../../globalStyles";
// import { Props } from "./index";
import media from "../../media";

export const ProfileContainer = styled.div`
	.form {
		label {
			font-size: 0.9rem;
			color: ${theme.primaryFont};
		}
		input,
		select {
			width: 100%;
			background: white;
			border: 1px solid ${theme.primaryColor};
			padding: 10px;
			font-size: 1.125rem;
			color: ${theme.primaryFont};
			margin-bottom: 1rem;
		}
		.passwordField {
			position: relative;
		}
		button {
			&:not(.togglePass) {
				width: 100%;
				color: ${theme.primaryFont};
				&:not(.linkBtn) {
					padding: 12px;
					text-align: center;
					background-color: ${theme.buttonColor};
					font-weight: 700;
					font-size: 1.125rem;
					margin-bottom: 8px;
					&:hover {
						opacity: 0.7;
					}
				}
				background: none;
			}
			&.togglePass {
				position: absolute;
				top: 44%;
				right: 10px;
				transform: translateY(-50%);
				background: none;
			}
		}
	}
`;
