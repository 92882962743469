import React from 'react'

type QuestionsContextType = {
}

const QuestionsContext = React.createContext<QuestionsContextType>({} as QuestionsContextType)

type Props = {
    children: React.ReactNode
}

export const QuestionsProvider = ({ children }: Props) => {

    const value = {
    }

    return <QuestionsContext.Provider value={value}>{children}</QuestionsContext.Provider>
}

export const useQuestionContext = () => React.useContext(QuestionsContext)