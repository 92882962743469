import { IAnswer } from "../types/question";
import api from "./api";
import BaseService from "./base.service";
import { headers } from "./config";

export default class QuestionsService extends BaseService {
	constructor() {
		super(`/avaliacoes/questionario/`);
	}

	async answer(patientID: string | number, answers: IAnswer[]) {
		try {
			const response = await api.post(`${this.resource}${patientID}/responder/`, answers, headers);
			return response.data;
		} catch (error) {
			console.error("Erro ao salvar a avaliação", error);
			return { error: "Erro ao salvar a avaliação, tente novamente mais tarde." };
		}
	}

	async getById(avid: string | number) {
		try {
			const response = await api.get(`/avaliacoes/avaliacoes/${avid}`);
			return response.data;
		} catch (error) {
			console.error("Erro ao salvar a avaliação", error);
			return { error: "Erro ao salvar a avaliação, tente novamente mais tarde." };
		}
	}

	async updateById(avid: string | number, answers: IAnswer[]) {
		try {
			const response = await api.post(`/avaliacoes/avaliacoes/${avid}/`, answers, headers);
			return response.data;
		} catch (error) {
			console.error("Erro ao salvar a avaliação", error);
			throw new Error("Erro ao salvar a avaliação, tente novamente mais tarde.");
		}
	}
}
